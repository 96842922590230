import {computed} from "vue"
import {useStore} from "vuex"
import {useI18n} from "vue-i18n"
import {useAppUtils} from "@/composables/useAppUtils"

export function useDatepicker () {

  const store = useStore()
  const {t} = useI18n()
  const {
    get2SymbolsLanguage,
  } = useAppUtils()

  const datepickerModelType = "yyyy-MM-dd"
  const settings = computed(() => store.getters['settings/getAll'])
  const userDateFormat = computed(() => settings.value?.date_format || 'MM/dd/yyyy')
  const weekStart = computed(() => settings.value?.beginning_week || 0)
  const dayNames = computed(() => {
    const days = [t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa'), t('Su')]
    if (weekStart.value == 0) {
      const sunday = days.pop()
      if (sunday) {
        days.unshift(sunday)
      }
    }
    return days
  })
  const datepickerLang = get2SymbolsLanguage()

  return {
    datepickerModelType,
    userDateFormat,
    weekStart,
    dayNames,
    datepickerLang,
  }

}